<script setup lang="ts">
import type { JamBaseIconSize } from '@b2b-platform/manage-job-ads/components/JamBaseIcon.vue';

export type JamBaseButtonVariant =
    | 'blue'
    | 'blue-outline'
    | 'dark-gray-outline'
    | 'green'
    | 'green-outline'
    | 'red'
    | 'white-outline';

const props = defineProps({
    /**
     * @description
     * Disables button through js/css, but keeps it perceivable for screen readers.
     */
    ariaDisabled: {
        default: false,
        type: Boolean,
    },
    /**
     * @description
     * Disables button
     *
     * ♿️ Not perceivable for screen readers anymore.
     * Use aria-disabled="true" where applicable.
     *
     */
    disabled: {
        default: false,
        type: Boolean,
    },
    fullWidth: {
        default: false,
        type: Boolean,
    },
    hideLabelMobile: {
        default: false,
        type: Boolean,
    },
    iconName: {
        default: '',
        type: String,
    },
    iconSize: {
        default: 'large',
        type: String as PropType<JamBaseIconSize>,
    },
    isSmall: {
        default: false,
        type: Boolean,
    },
    label: {
        default: '',
        type: String,
    },

    pending: {
        default: false,
        type: Boolean,
    },
    /**
     * @description
     * Automatically resolves the component as 'a' if provided
     *
     * Use this instead of 'href' prop.
     * NuxtLinks can handle internal and external links.
     *
     */
    to: {
        default: undefined,
        type: String as PropType<string>,
    },
    type: {
        default: 'button',
        type: String as PropType<'button' | 'submit'>,
    },
    variant: {
        default: 'green',
        type: String as PropType<JamBaseButtonVariant>,
    },
});

const linkOrButton = computed(() =>
    props.to ? resolveComponent('NuxtLink') : 'button',
);

const isActive = computed(() => !(props.disabled || props.ariaDisabled));

const baseButton = ref<HTMLElement | null>(null);

watch(
    () => props.ariaDisabled,
    () => {
        if (props.ariaDisabled) {
            baseButton.value?.addEventListener('click', (ev) =>
                ev.preventDefault(),
            );
        }
    },
);

onUnmounted(() => {
    if (baseButton.value instanceof HTMLElement) {
        baseButton.value.removeEventListener('click', (ev) =>
            ev.preventDefault(),
        );
    }
});
</script>

<template>
    <Component
        :is="linkOrButton"
        ref="baseButton"
        :type="props.to ? undefined : props.type"
        :to="props.to"
        :class="{
            'min-w-[120px]': !hideLabelMobile && (label || $slots.default),
            'w-full': !hideLabelMobile && fullWidth,
            'w-fit': !hideLabelMobile && !fullWidth,
            'w-auto': hideLabelMobile,
            'h-8 px-4 py-3': isSmall,
            'h-9 p-4': !isSmall,
            'cursor-not-allowed bg-gray-300 text-white':
                !isActive && !variant.includes('-outline'),
            'cursor-not-allowed border border-gray-300 text-gray-300':
                !isActive && variant.includes('-outline'),
            'text-white hover:text-white focus-visible:outline-offset-2 active:text-white':
                isActive && !variant.includes('-outline'),
            'focus-visible:outline-offset-3 border hover:text-white active:text-white':
                isActive && variant.includes('-outline'),
            'bg-blue-600 hover:bg-blue-700 active:bg-blue-900':
                isActive && variant === 'blue',
            'border-blue-600 text-blue-600 hover:bg-blue-700 active:bg-blue-900':
                isActive && variant === 'blue-outline',
            'border-gray-900 bg-transparent text-gray-900 hover:bg-gray-800 active:bg-gray-900':
                isActive && variant === 'dark-gray-outline',
            'bg-green-600 hover:bg-green-800 active:bg-green-900':
                isActive && variant === 'green',
            'border-green-800 bg-transparent text-green-800 hover:bg-green-800 active:bg-green-900':
                isActive && variant === 'green-outline',
            'bg-red-500 hover:bg-red-700 active:bg-red-800':
                isActive && variant === 'red',
            'border-white text-white hover:border-green-600 hover:bg-green-600 active:border-green-800 active:bg-green-800':
                isActive && variant === 'white-outline',
        }"
        class="inline-flex items-center justify-center gap-2 rounded font-semibold focus-visible:outline-2 focus-visible:outline-blue-600"
        :disabled="disabled"
        :aria-disabled="ariaDisabled"
    >
        <JamBaseIcon v-if="iconName" :icon-name="iconName" :size="iconSize" />
        <div v-if="label || $slots.default || pending" class="relative">
            <span
                v-if="label"
                class="px-2"
                :class="[
                    hideLabelMobile && 'hidden md:inline',
                    { 'opacity-0': pending },
                ]"
            >
                {{ label }}
            </span>
            <span
                v-if="$slots.default"
                class="px-2"
                :class="{
                    'opacity-0': pending,
                }"
                ><slot
            /></span>
            <JamBaseIcon
                v-if="pending"
                icon-name="loading-circle"
                class="absolute left-[50%] top-0 ml-[-12px] animate-spin"
            />
        </div>
    </Component>
</template>
